import { useState } from 'react';

const useInput = base => {
  const [value, setValue] = useState(base);

  return [
    value,
    setValue,
    event => {
      setValue({ ...value, [event.target.name]: event.target.value });
    }
  ];
};

export default useInput;