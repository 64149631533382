import Styled from 'styled-components';

const Box = Styled.div`
  position: ${props => props.position};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  width: ${props => props.width};
  height: ${props => props.height};
  display: ${props => props.display};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  flex: ${props => props.flex};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  box-shadow: ${props => props.boxShadow};
  border: ${props => props.border};
  border-right: ${props => props.borderRight};
  border-radius: ${props => props.borderRadius};
  border-color: ${props => props.borderColor};
  overflow: ${props => props.overflow};
  overflow-y: ${props => props.overflowY};
  cursor: ${props => props.cursor};
  user-select: ${props => props.userSelect};
`;

export default Box;