import { useEffect, useState, useRef } from 'react';
import { useQueryParams } from 'raviger';
import * as D3 from 'd3';
import BatchApi from '../../../../../api/batch';
import Box from '../../../../../components/atoms/box';

const MARGIN = 12;
const NODE_RADIUS = 10;
const NODE_TEXT_MARGIN = 20;

const Graph = ({ id }) => {
  const [query] = useQueryParams();

  const [treeData, setTreeData] = useState();

  const chartRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(
    () => {
      BatchApi.tree(id, query.direction ? query.direction : 'both')
        .then(response => {
          let formatted = [];

          for (const batch of response.data) {
            if (!formatted[batch.depth]) formatted.push([]);
            formatted[batch.depth].push(batch);
          }

          formatted = formatted.reverse();

          setTreeData(formatted);
        });
    },
    [id, query.direction]
  );

  useEffect(
    () => {
      if (!treeData) return;

      const data = treeData;

      const chart = chartRef.current;
      const canvas = canvasRef.current;

      const margin = MARGIN;

      const width = chart.clientWidth;
      const height = chart.clientHeight;

      const widthMargin = width - margin * 2;
      const heightMargin = height - margin * 2;

      const svg = D3
        .select(canvas);

      // set size
      svg
        .attr('width', width)
        .attr('height', height);

      const global = svg
        .append('g');

      // clean current graph
      global
        .selectAll('g')
        .remove();

      svg
        .call(
          D3
            .zoom()
            .on('zoom', event => {
              global.attr('transform', `translate(0, ${event.transform.y})`);
              // global.attr('transform', event.transform);
            })
        );

      const content = global
        .append('g')
        .attr('transform', `translate(${margin}, ${margin})`);

      let maxRowLength = 0;

      for(const row of data) {
        if(row.length > maxRowLength) maxRowLength = row.length;
      }

      let totalHeight = maxRowLength * NODE_RADIUS + (maxRowLength - 1) * 15;

      if(totalHeight < heightMargin)
        totalHeight = heightMargin;

      const nodes = {};

      // process positions
      for (let i = 0; i < data.length; ++i) {
        for (let j = 0; j < data[i].length; ++j) {
          const batch = data[i][j];

          const colLength = data.length - 1;
          const rowLength = data[i].length - 1;

          const x = (colLength === 0 ? widthMargin / 2 : (widthMargin / colLength) * i);
          const y = (rowLength === 0 ? heightMargin / 2 : (totalHeight / rowLength) * j);

          nodes[batch.id] = {
            position: { x, y },
            radius: NODE_RADIUS
          };
        }
      }

      // links
      for (let i = 0; i < data.length; ++i) {
        for (let j = 0; j < data[i].length; ++j) {
          const batch = data[i][j];

          const rootPosition = nodes[batch.id].position;

          // link
          if (i > 0) {
            for (const heritage of batch.childs) {
              const batchHerited = nodes[heritage.batchFromId];

              if(!batchHerited)
                continue;

              const positionHerited = batchHerited.position;

              content
                .append('path')
                .attr('fill', 'none')
                .attr('stroke', '#ccc')
                .attr('stroke-width', '1px')
                .attr(
                  'd',
                  'M'
                  + positionHerited.x + ',' + positionHerited.y + ' '
                  + 'C'
                  + rootPosition.x + ',' + (positionHerited.y + ((rootPosition.y - positionHerited.y) / 2)) + ' '
                  + positionHerited.x + ',' + (rootPosition.y - ((rootPosition.y - positionHerited.y) / 2)) + ' '
                  + rootPosition.x + ',' + rootPosition.y
                );
            }
          }
        }
      }

      // nodes
      for (let i = 0; i < data.length; ++i) {
        for (let j = 0; j < data[i].length; ++j) {
          const batch = data[i][j];
          const nodeInfos = nodes[batch.id];
          const rootPosition = nodeInfos.position;
          const nodeRadius = nodeInfos.radius;
          const colLength = data.length - 1;

          // node
          const node = content
            .append('g');

          node
            .attr(
              'transform',
              'translate(' + rootPosition.x + ',' + rootPosition.y + ')'
            )
            .append('circle')
            .attr('fill', 'white')
            .attr('stroke', batch.id === parseInt(id) ? '#BE0F34' : '#ccc')
            .attr('stroke-width', 2)
            // .attr('r', nodeRadius);
            .attr('r', nodeRadius);

          // text
          const text = node.append('text')
            .attr('dy', '.25rem')
            .attr('font-size', '12px')
            .attr('x', NODE_TEXT_MARGIN)
            .text(batch.product.name);

          if (i === colLength) {
            text
              .attr('x', -NODE_TEXT_MARGIN)
              .style('text-anchor', 'end');
          }
        }
      }
    },
    [treeData, id]
  );

  return (
    <Box
      position="relative"
      display="flex"
      flex="1"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <Box
        position="absolute"
        display="flex"
        width="100%"
        height="100%"
        padding="0 0 1.67vw 0"
      >
        <Box flex="1" ref={chartRef}>
          <svg ref={canvasRef} />
        </Box>
      </Box>
    </Box>
  );
};

export default Graph;