import axios from 'axios';
import Store from '../redux';
import { signOut } from '../redux/user';

export const baseURL = process.env.REACT_APP_BACK;

axios.defaults.baseURL = baseURL;
axios.defaults.timeout = 120000;

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!error.response) return Promise.reject(error);

    const { status } = error.response;

    if (status === 401) Store.dispatch(signOut);

    return Promise.reject(error);
  }
);

export default axios;