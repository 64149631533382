import { useState, useEffect, useRef, useCallback } from 'react';
import Styled from 'styled-components';
import { ChevronDown, X } from 'react-feather';
import Box from '../../atoms/box';
import Text from '../../atoms/text';

const Select = ({ name, options, selected, onSelect, onRemove }) => {
  const [toggle, setToggle] = useState(false);
  const selectRef = useRef();
  const closeRef = useRef();

  const clickToggle = event => {
    if(closeRef.current && closeRef.current.contains(event.target))
      return;

    setToggle(current => !current);
  };

  const closeDropdown = useCallback(
    () => {
      setToggle(false);
    },
    []
  );

  useEffect(
    () => {
      const clickOutside = event => {
        if (!selectRef.current || selectRef.current.contains(event.target))
          return;

        closeDropdown();
      };

      document.addEventListener('mousedown', clickOutside);

      return () => {
        document.removeEventListener('mousedown', clickOutside);
      };
    },
    [closeDropdown]
  );

  const drawMarker = () => {
    if(selected) {
      return (
        <X
          ref={closeRef}
          color={(toggle || selected) ? 'white' : 'rgba(100, 100, 100, 0.7)'}
          size="16px"
          onClick={
            () => {
              onRemove();
            }
          }
        />
      );
    }

    return (
      <ChevronDown
        color={(toggle || selected) ? 'white' : 'rgba(100, 100, 100, 0.7)'}
        size="16px"
      />
    );
  };

  return (
    <Box position="relative" ref={selectRef}>
      <Box
        display="flex"
        alignItems="center"
        padding="8px 16px"
        border="2px solid rgba(100, 100, 100, 0.7)"
        borderColor={(toggle || selected) && '#BE0F34'}
        backgroundColor={(toggle || selected) && '#BE0F34'}
        borderRadius="24px"
        cursor="pointer"
        userSelect="none"
        onClick={clickToggle}
      >
        <Text
          fontSize="0.75rem"
          color={(toggle || selected) ? 'white' : 'rgba(100, 100, 100, 0.7)'}
          margin="0 10px 0 0"
        >
          {name}
        </Text>
        {drawMarker()}
      </Box>
      <Dropdown active={toggle}>
        {
          options.map((option, index) => (
            <Entry
              key={index}
              padding="15px 20px 15px 20px"
              cursor="pointer"
              onClick={
                () => {
                  closeDropdown();
                  onSelect(option.value);
                }
              }
            >
              <Text fontSize="0.75rem">
                {option.name}
              </Text>
            </Entry>
          ))
        }
      </Dropdown>
    </Box>
  );
};

const Dropdown = Styled(Box)`
  display: ${props => props.active ? 'block' : 'none'};
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: 150%;
  margin-top: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const Entry = Styled(Box)`
  &:hover {
    background-color: rgba(190, 15, 52, 0.03)
  }
`;

export default Select;