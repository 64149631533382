import { useEffect, useCallback } from 'react';
import { Redirect } from 'raviger';
import { useSelector, useDispatch } from 'react-redux';
import { signIn } from '../../redux/user';
import { useInput } from '../../hooks';
import GroupeClarins from '../../assets/groupe-clarins.png';
import Box from '../../components/atoms/box';
import Image from '../../components/atoms/image';
import Label from '../../components/molecules/label';
import TextInput from '../../components/atoms/text-input';
import Text from '../../components/atoms/text';
import Link from '../../components/atoms/link';
import Button from '../../components/atoms/button';
import Colorize from '../../components/atoms/colorize';

const Signin = () => {
  const [credentials,, setCredentialsOnChange] = useInput({
    email: '',
    password: ''
  });
  const hasUser = useSelector(state => state.user.hasUser);
  const dispatch = useDispatch();

  const initSignIn = useCallback(
    () => {
      dispatch(signIn(credentials));
    },
    [credentials, dispatch]
  );

  useEffect(() => {
    const onEnter = event => {
      if (event.key === 'Enter') initSignIn();
    };

    document.addEventListener('keyup', onEnter);

    return () => { document.removeEventListener('keyup', onEnter); };
  }, [initSignIn]);

  if(hasUser) return <Redirect to="/dashboard" />;

  return (
    <Box width="100%" height="100%" display="flex" justifyContent="space-evenly" alignItems="center" flexDirection="column">
      <Box width="754px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Image src={GroupeClarins} width="324px" />
        <Text fontSize="1rem" fontWeight="700" textAlign="center">
          Bienvenue sur l’outil de traçabilité du Groupe Clarins. Veuillez sélectionner votre métier.
        </Text>
      </Box>
      <Box width="480px">
        <Label text="Adresse mail">
          <TextInput type="email" name="email" onChange={setCredentialsOnChange} />
        </Label>
      </Box>
      <Box width="480px">
        <Label text="Mot de passe">
          <TextInput type="password" name="password" onChange={setCredentialsOnChange} />
        </Label>
        <Link href="/lostpassword">
          <Text fontSize="0.66rem" color="#646464" margin="6px 0 0 0">
            Mot de passe oublié ?
          </Text>
        </Link>
      </Box>
      <Box width="480px" display="flex" justifyContent="center" alignItems="center">
        <Button onClick={initSignIn}>Se connecter</Button>
      </Box>
      <Box width="480px" display="flex" justifyContent="center" alignItems="center">
        <Link href="/signup">
          <Text fontSize="0.66rem" color="#646464">
            Pas de compte ? <Colorize>Cliquez ici</Colorize> pour en créer un
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default Signin;