import { TileLayer } from 'react-leaflet';
import Box from '../../../../../components/atoms/box';
import MapComponent from '../../../../../components/molecules/map';

const Map = () => {
  return (
    <Box flex="1" display="flex" borderRadius="8px" overflow="hidden">
      <Box flex="1" padding="0 0 1.67vw 0">
        <MapComponent
          center={[48.754, 2.333]}
          zoom={4}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapComponent>
      </Box>
    </Box>
  );
};

export default Map;