import { Redirect } from 'raviger';
import Signin from './signin';
import Signup from './signup';
import PrivateRoute from '../components/atoms/private-route';
import Dashboard from './dashboard';

const router = {
  '/': () => <Redirect to="/dashboard" />,
  '/signin': () => <Signin />,
  '/signup': () => <Signup />,
  '/dashboard*': () => <PrivateRoute component={Dashboard} />
};

export default router;