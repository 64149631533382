import Box from '../../atoms/box';
import Text from '../../atoms/text';
import Link from '../../atoms/link';
import * as Icon from 'react-feather';

const Card = ({ icon, title, description, href, basePath }) => {
  const IconComponent = Icon[icon];

  return (
    <Link href={href} basePath={basePath}>
      <Box flex="1" backgroundColor="#BE0F34" borderRadius="16px" padding="16px">
        <Box display="flex" alignItems="center" margin="0 0 12px 0">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="32px"
            height="32px"
            backgroundColor="white"
            borderRadius="50%"
            margin="0 12px 0 0"
          >
            <IconComponent color="black" size="16px" />
          </Box>
          <Text fontSize="1rem" fontWeight="700" color="#ebebeb">
            { title }
          </Text>
        </Box>
        <Text fontSize="0.583rem" fontWeight="400" color="white">
          { description }
        </Text>
      </Box>
    </Link>
  );
};

export default Card;