import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'raviger';
import { signOut } from '../../../redux/user';

const Signout = () => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(signOut);
    },
    [dispatch]
  );

  return (
    <Redirect to="/signin" />
  );
};

export default Signout;