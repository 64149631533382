import { useRoutes, useRedirect } from 'raviger';
import router from './router';
import Box from '../../components/atoms/box';
import Sidebar from '../../components/organisms/sidebar';
import Searchbar from '../../components/molecules/search-bar';
import Breadcrumb from '../../components/molecules/breadcrumb';

const basePath = '/dashboard';

const Dashboard = () => {
  const routes = useRoutes(router, { basePath });

  useRedirect(basePath, `${basePath}/home`);

  return (
    <Box width="100%" height="100%" display="flex">
      <Box width="8.33vw" height="100%">
        <Sidebar />
      </Box>
      <Box flex="1" display="flex" flexDirection="column">
        <Box position="relative" flex="1">
          <Box
            display="flex"
            flexDirection="column"
            position="absolute"
            width="100%"
            height="100%"
            overflowY="auto"
            padding="0 2.08vw"
          >
            <Box
              width="100%"
              margin="2vw 0 2vw 0"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Searchbar width="55%" />
            </Box>
            <Box margin="0 0 1.67vw 0">
              <Breadcrumb basePath={basePath} />
            </Box>
            <Box display="flex" flex="1">
              { routes }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;