import Styled from 'styled-components';

const Button = Styled.button`
  font-size: 1rem;
  font-weight: 400;
  border-radius: 16px;
  border: none;
  padding: 10px 26px;
  background-color: #BE0F34;
  color: #FFF;
  user-select: none;
  cursor: pointer;

  ${
    props => (
      props.secondTheme &&
      `
        border-radius: 24px;
        backgroundColor: #BE0F34;
        color: white;
        font-size: 0.75rem;
      `
    )
  }
`;

export default Button;