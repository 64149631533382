import React from 'react';
import { useRoutes, useLocationChange } from 'raviger';
import router from './router';
import Box from '../components/atoms/box';

const Root = () => {
  const routes = useRoutes(router);

  useLocationChange(() => window.scrollTo(0, 0));

  return (
    <Box height="100vh" display="flex">
      { routes }
    </Box>
  );
};

export default Root;