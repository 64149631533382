import Api from './index';

const Default = route => ({
  create(object) {
    return Api.post(route, object);
  },

  findOne(id) {
    return Api.get(`${route}/${id}`);
  },

  find(params) {
    return Api.get(route, { params });
  },

  update(object) {
    return Api.patch(route, object);
  },

  delete(id) {
    return Api.patch(`${route}/${id}`);
  }
});

export default Default;