import Box from '../../atoms/box';
import Image from '../../atoms/image';
import SidebarEntry from '../../molecules/sidebar-entry';
import GroupeClarinsSmall from '../../../assets/groupe-clarins-small.png';

const Sidebar = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      padding="2.08vw 0 2.08vw 0"
      borderRight="4px solid rgba(235, 230, 230, 0.8)"
    >
      <Box width="100%" display="flex" flexDirection="column">
        {/* <Box display="flex" justifyContent="center" margin="0 1vw 2.08vw 1vw">
          <Image src={GroupeClarinsSmall} />
        </Box> */}
        <SidebarEntry icon="Home" label="Accueil" href="/home" basePath="/dashboard" margin="0 0 1.82vw 0" />
        <SidebarEntry icon="Package" label="Lots" href="/batch" basePath="/dashboard" margin="0 0 1.82vw 0" />
        <SidebarEntry icon="Truck" label="Fournisseurs" href="/entity" basePath="/dashboard" margin="0 0 1.82vw 0" />
        <SidebarEntry icon="Globe" label="Dépôts" href="/site" basePath="/dashboard" margin="0 0 1.82vw 0" />
      </Box>
      <Box width="100%" display="flex" flexDirection="column">
        <SidebarEntry icon="User" label="Compte" href="/user" basePath="/dashboard" margin="1.82vw 0 0 0" />
        <SidebarEntry icon="LogOut" label="Déconnexion" href="/signout" basePath="/dashboard" margin="1.82vw 0 0 0" />
      </Box>
    </Box>
  );
};

export default Sidebar;