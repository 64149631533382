import { useEffect, useState } from 'react';
import { formatDate } from '../../../../utils';
import Box from '../../../../components/atoms/box';
import Subtitle from '../../../../components/atoms/subtitle';
import Table from '../../../../components/molecules/table';
import EntityApi from '../../../../api/entity';
import MovementApi from '../../../../api/movement';

const entityMeta = [
  { name: 'Type', key: 'type' },
  { name: 'Code', key: 'code' },
  { name: 'Nom', size: 2, key: 'name' }
];

const movementMeta = [
  { name: 'Type', key: 'type' },
  { name: 'Nom', size: 2, key: 'batch.product.name' },
  { name: 'Quantité', key: 'batch.product.quantity' },
  { name: 'Acteur', key: 'entity.name' },
  { name: 'Dépôt', size: 2, key: 'site.name' },
  { name: 'Date', key: 'time', format: value => formatDate(value) }
];

const EntityDetails = ({ id }) => {
  const [entity, setEntity] = useState();

  useEffect(
    () => {
      EntityApi.findOne(id)
        .then(response => setEntity(response.data));
    },
    [id]
  );

  return (
    <Box flex="1">
      <Box margin="0 0 32px 0">
        <Table
          meta={entityMeta}
          defaultRows={entity && [entity]}
        />
      </Box>
      <Box margin="0 0 5px 0">
        <Subtitle color="#646464">Joue un rôle dans</Subtitle>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          api={MovementApi}
          meta={movementMeta}
          where={{ entityId: id }}
          limit={10}
          enableClickable
          defaultUrl="/dashboard/batch/details"
          defaultClickableField="batchId"
        />
      </Box>
    </Box>
  );
};

export default EntityDetails;