import { useEffect, useCallback } from 'react';
import { navigate } from 'raviger';
import UserApi from '../../api/user';
import { useInput } from '../../hooks';
import GroupeClarins from '../../assets/groupe-clarins.png';
import Box from '../../components/atoms/box';
import Image from '../../components/atoms/image';
import Label from '../../components/molecules/label';
import TextInput from '../../components/atoms/text-input';
import Text from '../../components/atoms/text';
import Button from '../../components/atoms/button';

const Signup = () => {
  const [credentials, ,setCredentialsOnChange] = useInput({
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const initSignUp = useCallback(
    async () => {
      await UserApi.signUp(credentials);
      navigate('/signin');
    },
    [credentials]
  );

  useEffect(() => {
    const onEnter = event => {
      if (event.key === 'Enter') initSignUp();
    };

    document.addEventListener('keyup', onEnter);

    return () => { document.removeEventListener('keyup', onEnter); };
  }, [initSignUp]);

  return (
    <Box width="100%" height="100%" display="flex" justifyContent="space-evenly" alignItems="center" flexDirection="column">
      <Box width="754px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Image src={GroupeClarins} width="324px" />
        <Text fontSize="1rem" fontWeight="700" textAlign="center">
          Renseigner vos informations afin de créer votre compte.
        </Text>
      </Box>
      <Box width="1032px" display="flex" justifyContent="space-between">
        <Box width="480px">
          <Label text="Nom">
            <TextInput name="lastName" onChange={setCredentialsOnChange} />
          </Label>
        </Box>
        <Box width="480px">
          <Label text="Prénom">
            <TextInput name="firstName" onChange={setCredentialsOnChange} />
          </Label>
        </Box>
      </Box>
      <Box width="1032px">
        <Label text="Adresse mail">
          <TextInput type="email" name="email" onChange={setCredentialsOnChange} />
        </Label>
      </Box>
      <Box width="1032px" display="flex" justifyContent="space-between">
        <Box width="480px">
          <Label text="Mot de passe">
            <TextInput type="password" name="password" onChange={setCredentialsOnChange} />
          </Label>
        </Box>
        <Box width="480px">
          <Label text="Confirmation de mot de passe">
            <TextInput type="password" name="confirmPassword" onChange={setCredentialsOnChange} />
          </Label>
        </Box>
      </Box>
      <Box width="1032px" display="flex" justifyContent="flex-end">
        <Button onClick={initSignUp}>Créer votre compte</Button>
      </Box>
    </Box>
  );
};

export default Signup;