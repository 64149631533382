import { useState } from 'react';
import Styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Box from '../../atoms/box';
import Text from '../../atoms/text';

const Pagination = ({ value, maxValue, onChange }) => {
  const [page, setPage] = useState(value);

  const changePage = amount => () => {
    const newPage = page + amount;

    if (newPage < 1)
      return;

    if (maxValue && newPage > maxValue)
      return;

    setPage(newPage);

    onChange(newPage);
  };

  return (
    <Box display="flex" alignItems="center">
      <Text fontSize="0.75rem" fontWeight="400">Page {page}</Text>
      <Arrow onClick={changePage(-1)}><ChevronLeft /></Arrow>
      <Arrow onClick={changePage(1)}><ChevronRight /></Arrow>
    </Box>
  );
};

export default Pagination;

const Arrow = Styled.div`
  padding: 16px;
  cursor: pointer;
`;