import Box from '../../atoms/box';
import Text from '../../atoms/text';
import * as Icon from 'react-feather';
import { usePath } from 'raviger';
import Link from '../../atoms/link';

const SidebarEntry = ({ icon, label, href, basePath, exact, margin }) => {
  const IconComponent = Icon[icon];
  const path = usePath(basePath);

  const isActive = (exact && path === href) || path.includes(href);

  const color = isActive ? '#BE0F34' : 'rgba(100, 100, 100, 0.7)';

  return (
    <Box margin={margin}>
      <Link href={href} basePath={basePath}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          color={color}
          >
            <IconComponent color={color} size="1.67vw" />
            <Text fontSize="0.69rem" fontWeight="400" margin="5px 0px 0px 0px" color={color}>{ label }</Text>
          </Box>
      </Link>
    </Box>
  );
};

export default SidebarEntry;