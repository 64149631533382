import { useState } from 'react';
import Box from '../../../components/atoms/box';
import Table from '../../../components/molecules/table';
import Pagination from '../../../components/molecules/pagination';
import EntityApi from '../../../api/entity';

const entityMeta = [
  { name: 'Type', size: 1, key: 'type', enableOrder: true },
  { name: 'Code', size: 1, key: 'code', enableOrder: true },
  { name: 'Nom', size: 2, key: 'name', enableOrder: true }
];

// const filter = [
//   {
//     name: 'Type',
//     key: 'type',
//     options: [
//       { name: 'supplier', value: 'supplier' },
//       { name: 'manufacturer', value: 'manufacturer' },
//       { name: 'transporter', value: 'transporter' }
//     ]
//   }
// ];

const Batch = () => {
  const [page, setPage] = useState(1);

  return (
    <Box flex="1" margin="0 0 32px 0">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
        </Box>
        <Box>
          <Pagination value={page} onChange={setPage} />
        </Box>
      </Box>
      <Table
        api={EntityApi}
        meta={entityMeta}
        page={page}
        limit={20}
        defaultOrder="-id"
        defaultUrl="/dashboard/entity/details"
        enableClickable
      />
    </Box>
  );
};

export default Batch;