import Api from '../index';
import Default from '../default';

const route = '/user';

const userApi = {
	signIn({ email, password }) {
		return Api.post(`${route}/signin`, { email, password });
	},

	signUp({ firstName, lastName, email, password }) {
		return Api.post(`${route}/signup`, { firstName, lastName, email, password });
	},

  applyToken(token) {
		Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },

  ...Default(route)
};

export default userApi;