import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

import 'inter-ui/inter.css';

import GlobalStyle from './components/atoms/global-style';
import Root from './pages';
import Store from './redux';
import { checkLocalAuth } from './redux/user';

Store.dispatch(checkLocalAuth);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <GlobalStyle />
      <Root />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);