import { resolveObjectPath } from '../../../utils';
import Styled from 'styled-components';
import Box from '../../atoms/box';

const resolveValue = (row, meta) => {
  const resolved = resolveObjectPath(row, meta.key);
  if(meta.format) return meta.format(resolved);
  return resolved;
};

const TableVertical = ({ meta, rows = [] }) => {
  return (
    <Box display="flex" flex="1">
      <HeaderSection>
        <HeaderRow>
          {
            meta.map((meta, index) => (
              <HeaderCol key={index}>
                {meta.name}
              </HeaderCol>
            ))
          }
        </HeaderRow>
      </HeaderSection>
      <BodySection>
        {
          rows.map((row, indexRow) => (
            <BodyRow key={indexRow}>
              {
                meta.map((meta, indexMeta) => (
                  <BodyCol key={indexMeta}>{resolveValue(row, meta)}</BodyCol>
                ))
              }
            </BodyRow>
          ))
        }
      </BodySection>
    </Box>
  );
};

const Section = Styled.div`
  display: flex;
  font-size: 0.75rem;
  color: rgba(100, 100, 100, 0.8)
`;
const Row = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Col = Styled.div`
  flex: 1;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(100, 100, 100, 0.11);
  }
`;
const HeaderSection = Styled(Section)``;
const HeaderRow = Styled(Row)`
  border-right: 1px solid rgba(100, 100, 100, 0.11);
`;
const HeaderCol = Styled(Col)`
  font-weight: 700;
  padding: 9px 15px 9px 0;
`;
const BodySection = Styled(Section)`
  flex: 1;
`;
const BodyRow = Styled(Row)``;
const BodyCol = Styled(Col)`
  padding: 9px 0 9px 15px;
`;

export default TableVertical;