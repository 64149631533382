import { useState } from 'react';
import Styled from 'styled-components';
import { useQueryParams } from 'raviger';
import { formatDate, formatWhere, downloadFile } from '../../../utils';
import Box from '../../../components/atoms/box';
import Table from '../../../components/molecules/table';
import Pagination from '../../../components/molecules/pagination';
import Select from '../../../components/atoms/select';
import Button from '../../../components/atoms/button';
import BatchApi from '../../../api/batch';

const batchMeta = [
  { name: 'Type', size: 2, key: 'product.type', enableOrder: true },
  { name: 'Code', size: 2, key: 'product.code', enableOrder: true },
  { name: 'Lot', size: 2, key: 'code', enableOrder: true },
  { name: 'Lot fournisseur', size: 2, key: 'codeSupplier', enableOrder: true },
  { name: 'Numéro d\'ordre', size: 2, key: 'codeOrder', enableOrder: true },
  { name: 'Nom', size: 5, key: 'product.name', enableOrder: true },
  { name: 'Fabrication', size: 4, key: 'movementFabrication.entity.name', enableOrder: true },
  { name: 'Quantité', size: 2, key: 'quantity', enableOrder: true },
  { name: 'Date d\'ajout', size: 2.2, key: 'createdAt', format: value => formatDate(value), enableOrder: true }
];

const filterType = {
  name: 'Type',
  key: 'product.type',
  options: [
    { name: 'raw', value: 'raw' },
    { name: 'plant', value: 'plant' },
    { name: 'material', value: 'material' },
    { name: 'sauce', value: 'sauce' },
    { name: 'product', value: 'product' }
  ]
};

const Details = () => {
  const [query] = useQueryParams();
  const [where, setWhere] = useState(query);
  const [page, setPage] = useState(1);

  const downloadClick = async () => {
    const response = await BatchApi.downloadExport(formatWhere(where));
    downloadFile(response, 'export.csv');
  };

  return (
    <Box flex="1" margin="0 0 32px 0">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Filter>
            <Select
              name={filterType.name}
              options={filterType.options}
              selected={where[filterType.key]}
              onSelect={
                value => setWhere(current => ({ ...current, [filterType.key]: value }))
              }
              onRemove={
                () => setWhere(current => ({ ...current, [filterType.key]: undefined }))
              }
            />
          </Filter>
          <Filter>
            <Button secondTheme={true} onClick={downloadClick}>
              Exporter
            </Button>
          </Filter>
        </Box>
        <Box>
          <Pagination value={page} onChange={setPage} />
        </Box>
      </Box>
      <Table
        api={BatchApi}
        meta={batchMeta}
        where={where}
        page={page}
        limit={20}
        defaultOrder="-product.type,-id"
        enableClickable
        defaultUrl="/dashboard/batch/details"
      />
    </Box>
  );
};

export default Details;

const Filter = Styled.div`
  :not(:last-child) {
    margin-right: 10px;
  }
`;