import { format } from 'date-fns';

function productTypeToString(type) {
  switch(type) {
    case 'raw':
      return 'Brut';
    case 'plant':
      return 'Plante';
    case 'material':
      return 'Matière première';
    case 'conditioner':
      return 'Conditionneur';
    case 'product':
      return 'Produit';
    default:
      return 'Inconnu';
  }
}

// todo: ramda?
function resolveObjectPath(object, path) {
  path = path.split('.');
  let current = object;
  for(const key of path) {
    if(!current) return current;
    current = current[key];
  }
  return current;
}

function isNumeric(value) {
  return /^[0-9]+$/.test(value);
}

function debounce(func, time) {
	let timeout;

  return (...args) => {
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(func, time, ...args);
  };
};

const isUndefined = argument => {
  return argument === undefined;
};

const isNull = argument => {
  return argument === null;
};

const isNil = argument => {
  return isUndefined(argument) || isNull(argument);
};

const isObject = argument => {
  return !isNil(argument) && argument.__proto__.constructor.name === 'Object';
};

const formatDate = argument => {
  return argument
    ? format(new Date(argument), 'dd/MM/yyyy hh:mm:ss')
    : '';
};

const formatWhere = object => {
  const result = {};

  if(!object) return result;

  for (const key of Object.keys(object)) {
    result[`where.${key}`] = object[key];
  }

  return result;
};

const downloadFile = (response, filename) => {
  try {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  } catch (error) {}
};

export { productTypeToString, resolveObjectPath, isNumeric, debounce, isObject, formatDate, formatWhere, downloadFile };
