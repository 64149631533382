import { createStore, applyMiddleware } from 'redux';
import Thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducer from './root.reducer';

const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(Thunk))
);

export default store;