import Home from './home';
import Batch from './batch';
import BatchDetails from './batch/details';
import BatchDetailsGraph from './batch/details/graph';
import BatchDetailsMap from './batch/details/map';
import Entity from './entity';
import EntityDetails from './entity/details';
import Signout from './signout';

const router = {
  '/home': () => <Home />,
  '/batch': () => <Batch />,
  '/batch/details/:id': ({ id }) => <BatchDetails id={id} />,
  '/batch/graph/:id': ({ id }) => <BatchDetailsGraph id={id} />,
  '/batch/map/:id': ({ id }) => <BatchDetailsMap id={id} />,
  '/entity': () => <Entity />,
  '/entity/details/:id': ({ id }) => <EntityDetails id={id} />,
  '/signout': () => <Signout />
};

export default router;