import Styled from 'styled-components';

const TextInput = Styled.input.attrs(
  props => {
    const { type } = props;

    if(!type) return props;

    if(!['email', 'password', 'text'].includes(type))
      throw new Error('wrong text input type');

    return props;
  })`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: rgba(235, 230, 230, 0.5);
  border: 0;
  border-radius: 8px;
  padding: ${props => props.padding || '16px 16px'};
  font-size: 1rem;
  font-weight: 400;
  color: rgba(100, 100, 100, 0.8);
  outline-color: #BE0F34;
`;

export default TextInput;