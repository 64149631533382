import Box from '../../../components/atoms/box';
import Card from '../../../components/molecules/card';
import Subtitle from '../../../components/atoms/subtitle';
import Table from '../../../components/molecules/table';
import BatchApi from '../../../api/batch';

const batchMeta = [
  { name: 'Type', size: 1, key: 'product.type' },
  { name: 'Code', size: 1, key: 'product.code' },
  { name: 'Lot', size: 1, key: 'code' },
  { name: 'Numéro d\'ordre', size: 1, key: 'codeOrder' },
  { name: 'Nom', size: 3, key: 'product.name' },
  { name: 'Fabrication', size: 2, key: 'movementFabrication.entity.name' }
];

const Home = () => {
  return (
    <Box flex="1">
      <Box display="flex" margin="0 0 32px 0">
        <Box flex="1" margin="0 32px 0 0">
          <Card
            icon="Feather"
            title="Matières premières"
            description="Accédez à vos matières premières et retrouvez toutes les informations liées"
            href="/batch?product.type=material"
            basePath="/dashboard"
          />
        </Box>
        <Box flex="1" margin="0 32px 0 0">
          <Card
            icon="Truck"
            title="Fournisseurs"
            description="Accédez à vos fournisseurs et retrouvez toutes les informations liées"
            href="/entity"
            basePath="/dashboard"
          />
        </Box>
        <Box flex="1" margin="0 32px 0 0">
          <Card
            icon="Droplet"
            title="Sauces"
            description="Accédez à vos sauces et retrouvez toutes les informations liées"
            href="/batch?product.type=sauce"
            basePath="/dashboard"
          />
        </Box>
        <Box flex="1">
          <Card
            icon="Star"
            title="Produits"
            description="Accédez à vos produits et retrouvez toutes les informations liées"
            href="/batch?product.type=product"
            basePath="/dashboard"
          />
        </Box>
      </Box>
      <Box margin="0 0 5px 0">
        <Subtitle>Produits récents</Subtitle>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          api={BatchApi}
          meta={batchMeta}
          where={{ 'product.type': 'product' }}
          limit={5}
          enableClickable
          defaultUrl="/dashboard/batch/details"
        />
      </Box>
      <Box margin="0 0 5px 0">
        <Subtitle>Sauces récentes</Subtitle>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          api={BatchApi}
          meta={batchMeta}
          where={{ 'product.type': 'sauce' }}
          limit={5}
          enableClickable
          defaultUrl="/dashboard/batch/details"
        />
      </Box>
      <Box margin="0 0 5px 0">
        <Subtitle>Matières premières récentes</Subtitle>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          api={BatchApi}
          meta={batchMeta}
          where={{ 'product.type': 'material' }}
          limit={5}
          enableClickable
          defaultUrl="/dashboard/batch/details"
        />
      </Box>
      <Box margin="0 0 5px 0">
        <Subtitle>Plantes récentes</Subtitle>
      </Box>
      <Box margin="0 0 32px 0">
        <Table
          api={BatchApi}
          meta={batchMeta}
          where={{ 'product.type': 'plant' }}
          limit={5}
          enableClickable
          defaultUrl="/dashboard/batch/details"
        />
      </Box>
    </Box>
  );
};

export default Home;
