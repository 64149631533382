import { useState, useEffect } from 'react';
import Styled from 'styled-components';
import { navigate } from 'raviger';
import { ChevronDown, ChevronUp } from 'react-feather';
import { resolveObjectPath, formatWhere } from '../../../utils';
import Box from '../../atoms/box';
import Text from '../../atoms/text';

const resolveValue = (row, meta) => {
  const resolved = resolveObjectPath(row, meta.key);
  if(meta.format) return meta.format(resolved, row.id);
  return resolved;
};

const isASC = order => {
  return order[0] !== '-';
};

const Table = ({
  api,
  meta,
  where,
  page,
  limit,
  defaultRows,
  defaultOrder = '-id',
  defaultUrl = '',
  defaultClickableField = 'id',
  enableClickable = false
}) => {
  const [rows, setRows] = useState(defaultRows);
  const [order, setOrder] = useState(defaultOrder);

  useEffect(
    () => {
      setRows(defaultRows);
    },
    [defaultRows]
  );

  useEffect(
    () => {
      if(!api) return;

      api.find({ ...formatWhere(where), page, limit, order })
        .then(response => {
          setRows(response.data.rows);
        });
    },
    [api, where, order, page, limit]
  );

  const clickOrder = meta => {
    let newOrder = meta.key;
    if(!meta.enableOrder) return;
    if(order.includes(meta.key)) {
      if(isASC(order)) newOrder = `-${meta.key}`;
    }
    setOrder(newOrder);
  };

  const drawMarker = meta => {
    if(!meta.enableOrder) return;

    const size = 16;
    const activeColor = '#BE0F34';

    if(order.includes(meta.key)) {
      if(isASC(order))
        return <ChevronUp size={size} color={activeColor} />;

      return <ChevronDown size={size} color={activeColor} />;
    }
    return <ChevronDown size={size} />;
  };

  const drawBody = () => {
    return rows?.map((row, indexRow) => (
      <BodyRow key={indexRow} enableClickable={enableClickable} onClick={() => enableClickable && navigate(`${defaultUrl}/${row[defaultClickableField]}`)}>
        {
          meta.map((meta, indexMeta) => (
            <BodyCol key={indexMeta} relativeSize={meta.size}>{resolveValue(row, meta)}</BodyCol>
          ))
        }
      </BodyRow>
    ));
  };

  const drawEmptyBody = () => {
    return (
      <EmptyRow>
        <Text color="#000000">Aucune donnée disponible</Text>
      </EmptyRow>
    );
  };

  return (
    <Box>
      <HeaderSection>
        <HeaderRow>
          {
            meta.map((meta, index) => (
              <HeaderCol
                key={index}
                relativeSize={meta.size}
                clickable={meta.enableOrder}
                onClick={() => clickOrder(meta)}
              >
                {meta.name} {drawMarker(meta)}
              </HeaderCol>
            ))
          }
        </HeaderRow>
      </HeaderSection>
      <BodySection>
        {
          rows?.length <= 0 ?
          drawEmptyBody() :
          drawBody()
        }
      </BodySection>
    </Box>
  );
};

const Section = Styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(100, 100, 100, 0.8);
`;
const Row = Styled.div`
  display: flex;
  padding: 10px 16px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.05);
`;
const Col = Styled.div`
  flex: ${props => props.relativeSize || 1};
`;
const HeaderSection = Styled(Section)`
  font-weight: 700;
  background-color: rgba(100, 100, 100, 0.11);
  border-radius: 8px;
`;
const HeaderRow = Styled(Row)`
  padding: 15px 16px;
  border: none;
`;
const HeaderCol = Styled(Col)`
  padding: 0 2px;
  cursor: ${props => props.clickable && 'pointer'};
  /* white-space: nowrap; */
`;
const BodySection = Styled(Section)``;
const BodyRow = Styled(Row)`
  ${
    props => props.enableClickable &&
    `
      cursor: pointer;
      &:hover {
        background-color: rgba(190, 15, 52, 0.03)
      }
    `
  }
`;
const BodyCol = Styled(Col)`
  padding: 0 2px;
`;

const EmptyRow = Styled(Row)`
  display: flex;
  justify-content: center;
`;

export default Table;