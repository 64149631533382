// libs
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'raviger';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const hasUser = useSelector(state => state.user.hasUser);

  if(hasUser)
    return <Component {...rest} />;

  return <Redirect to="/signin" />;
};

export default PrivateRoute;