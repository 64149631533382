import Styled from 'styled-components';

const Title = Styled.h1`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  margin: ${props => props.margin || 0};
  color: ${props => props.color};
`;

export default Title;