import Styled from 'styled-components';
import Text from '../../atoms/text';

const Component = props => {
  return (
    <Label>
      <Text margin="0px 0px 5px 0px">
        { props.text }
      </Text>
      { props.children }
    </Label>
  );
};

export default Component;

const Label = Styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
`;