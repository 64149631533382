import { usePath } from 'raviger';
import { ChevronRight } from 'react-feather';
import { isNumeric } from '../../../utils';
import Box from '../../atoms/box';
import Title from '../../atoms/title';

const TITLES = {
  home: 'Accueil',
  batch: 'Lots',
  product: 'Produits',
  entity: 'Fournisseurs',
  details: 'Détails',
  graph: 'Graphique',
  map: 'Carte'
};

const Breadcrumb = ({ basePath }) => {
  const path = usePath(basePath);
  const relative = path.substring(basePath.length);
  const titles = relative.split('/');
  titles.shift();
  if(isNumeric(titles[titles.length - 1])) titles.pop();

  return (
    <Box display="flex">
      {
        titles.map((title, index) => {
          const isLast = index >= titles.length - 1;

          if(isLast && isNumeric(title)) return null;

          return (
            <Title
              key={title}
              fontSize="1.5rem"
              margin="0"
              fontWeight="700"
              color={!isLast ? 'rgba(131, 131, 131, 0.8)' : 'rgba(0, 0, 0, 0.8)'}
            >
              {TITLES[title]}{!isLast && <ChevronRight />}
            </Title>
          );
        })
      }
    </Box>
  );
};

export default Breadcrumb;