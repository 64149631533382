import Api from '../index';
import Default from '../default';

const route = '/batch';

const batchApi = {
	tree(id, direction) {
		return Api.get(`${route}/tree/${id}`, { params: { direction } });
	},

	downloadExport(params = {}) {
		params.limit = 999;

    return Api.get(`${route}/export`, { params, responseType: 'arraybuffer' });
	},

	downloadDocument(id, documentId) {
		return Api.get(`${route}/${id}/document/${documentId}`, { responseType: 'arraybuffer' });
	},

  ...Default(route)
};

export default batchApi;