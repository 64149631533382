import Styled from 'styled-components';

const Text = Styled.p`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  margin: ${props => props.margin || 0};
  color: ${props => props.color};
  cursor: ${props => props.cursor};
`;

export default Text;