import { useCallback, useEffect, useState, useRef } from 'react';
import Styled from 'styled-components';
import { Search } from 'react-feather';
import { useInput } from '../../../hooks';
import Box from '../../atoms/box';
import Text from '../../atoms/text';
import TextInput from '../../atoms/text-input';
import Link from '../../atoms/link';
import { debounce } from '../../../utils';
import BatchApi from '../../../api/batch';

const SearchBar = ({ width }) => {
  const [search, setSearch, setSearchOnChange] = useInput({ text: '' });
  const [result, setResult] = useState([]);
  const searchBarRef = useRef();

  const closeDropdown = useCallback(
    () => {
      setSearch({ text: '' });
      setResult([]);
    },
    [setSearch, setResult]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce(
      text => {
        if(text.length <= 0)
          return;

        BatchApi.find({
          page: 1,
          limit: 5,
          order: '-id',
          'where.or.entity.code.iLike': `*${text}*`,
          'where.or.entity.name.iLike': `*${text}*`,
          'where.or.product.code.iLike': `*${text}*`,
          'where.or.product.name.iLike': `*${text}*`,
          'where.or.code.iLike': `*${text}*`,
          'where.or.codeFOU.iLike': `*${text}*`,
          'where.or.codeOrder.iLike': `*${text}*`
        })
        .then(response => {
          setResult(response.data.rows);
        });
      },
      500
    ),
    []
  );

  useEffect(
    () => debounceCallback(search.text),
    [search.text, debounceCallback]
  );

  useEffect(
    () => {
      const clickOutside = event => {
        if (!searchBarRef.current || searchBarRef.current.contains(event.target))
          return;

        closeDropdown();
      };

      document.addEventListener('mousedown', clickOutside);

      return () => {
        document.removeEventListener('mousedown', clickOutside);
      };
    },
    [closeDropdown]
  );

  return (
    <Box
      position='relative'
      width={width}
      ref={searchBarRef}
    >
      <Box position='relative'>
        <SearchIcon size='1.67vw' />
        <TextInput
          width='100%'
          padding='1.35vw 0.83vw 1.35vw 72px'
          placeholder='Rechercher parmi les lots'
          name='text'
          value={search.text}
          onChange={setSearchOnChange}
        />
      </Box>
      <Dropdown active={result.length > 0}>
        {
          result.map((batch, index) => (
            <Link
              key={index}
              href={`/batch/details/${batch.id}`}
              basePath="/dashboard"
            >
              <Entry padding="15px 20px 15px 20px" onClick={closeDropdown}>
                <Text fontSize="0.75rem">
                  {batch.product.name}
                </Text>
                <Text>
                  {batch.code || 'INCONNU'}
                </Text>
              </Entry>
            </Link>
          ))
        }
      </Dropdown>
    </Box>
  );
};

const SearchIcon = Styled(Search)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
  color: #646464;
`;

const Dropdown = Styled(Box)`
  display: ${props => props.active ? 'block' : 'none'};
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  margin-top: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const Entry = Styled(Box)`
  &:hover {
    background-color: rgba(190, 15, 52, 0.03)
  }
`;

export default SearchBar;